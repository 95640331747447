<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <b-form @submit.prevent="">
          <details-card
            v-for="(item, index) in detailFields"
            :key="index"
            :header="item.header"
            :edit="item.edit || false"
            :fields="item.fields"
            :beForm="beForm"
            :data="book_entry_data"
            :repository="item.repository"
            :labels="labels"
          >
          </details-card>

          <book-entry-registry-details-card
            v-for="(item, i) in registryDetailsFields"
            :key="i"
            :header="item.header"
            :fields="item.fields"
            :data="book_entry_data"
            :repository="item.repository"
            :labels="labels"
            :relation="item.relation"
          >
          </book-entry-registry-details-card>

          <book-entry-various-accounting-details
            header="Dettaglio"
            :data="book_entry_data"
          >
          </book-entry-various-accounting-details>

          <b-card header="true" header-tag="header" class="mt-1">
            <div
              slot="header"
              class="d-flex justify-content-between align-items-center"
            >
              <p class="mb-0 d-inline-block align-middle">
                <b>Forme di Pagamento</b>
              </p>
              <b-button
                type="button"
                variant="lisaweb"
                title="Vai alla sezione di modifica"
                @click="onEdit"
                >Modifica
              </b-button>
            </div>
          </b-card>
        </b-form>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import ShowMixin from "@/mixins/ShowMixin";
import FormMixin from "@/mixins/FormMixin";
import DetailsCard from "@/components/DetailsCard";
import { toLocaleCurrency } from "@/utils/strings";
import BookEntryRegistryDetailsCard from "@/components/BookEntryRegistryDetailsCard";
import BookEntryVariousAccountingDetails from "@/components/BookEntryVariousAccountingDetails";

export default {
  mixins: [ShowMixin, FormMixin],
  name: "General",
  props: {
    resourceId: Number,
  },
  components: {
    BaseIcon,
    DetailsCard,
    BookEntryRegistryDetailsCard,
    BookEntryVariousAccountingDetails,
  },
  data() {
    return {
      repository: "book_entry",
      book_entry_data: [],
      detailFields: {
        book_entry: {
          header: "Movimento Contabile",
          edit: false,
          repository: "book_entry",
          fields: [
            {
              label: this.getDictionary("title", "book_entry"),
              value: "title",
            },
            {
              label: this.getDictionary("book_date", "book_entry"),
              value: "book_date",
              type: "datepicker",
            },
          ],
        },
        codes: {
          header: "Codici di riferimento",
          edit: false,
          repository: "book_entry",
          fields: [
            {
              label: this.getDictionary("salesman"),
              value: "broker.attributables.BUID",
              virtual: (detail) => {
                return detail.brokers[0]?.attributables?.BUID
                  ? detail.brokers[0]?.attributables?.BUID
                  : "";
              },
            },
            {
              label: this.getDictionary("insurer"),
              value: "insurer.code",
            },
          ],
        },
      },
      registryDetailsFields: {
        registry: {
          header: "Anagrafica",
          repository: "registry",
          relation: "correspondence",
          fields: [
            {
              label: this.getDictionary("attribute_NAME_value", "registry"),
              value: "NAME",
              status_registry: 0,
            },
            {
              label: this.getDictionary("attribute_SURN_value", "registry"),
              value: "SURN",
              status_registry: 0,
            },
            {
              label: this.getDictionary("attribute_NINO_value", "registry"),
              value: "NINO",
              status_registry: 0,
            },
            {
              label: this.getDictionary("attribute_DOB_value", "registry"),
              value: "DOB",
              status_registry: 0,
            },
            {
              label: this.getDictionary("attribute_CNAM_value", "registry"),
              value: "CNAM",
              status_registry: 1,
            },
            {
              label: this.getDictionary("attribute_CREG_value", "registry"),
              value: "CREG",
              status_registry: 1,
            },
          ],
        },
      },
    };
  },
  methods: {
    toLocaleCurrency,
    fetchForm() {
      this.isLoading = true;
      let queryString = `relations/byRegistry/byEntryDetail/byInsurer/byBroker/byVariousAccounting`;
      this.fetchShowForm(this.repository, this.resourceId, queryString)
        .then((response) => {
          const data = response.data.data;
          this.book_entry_data = data;
          this.isLoading = false;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
    },
    onEdit() {
      this.$router.push({
        name: `book_entries.edit`,
        params: { id: `${this.resourceId}`, mode: "V" },
      });
    },
  },
  mounted() {
    this.fetchForm();
  },
};
</script>

<style scoped lang="scss">
.card {
  border: none;
}
.col-md-4.border-bottom {
  text-transform: uppercase;
  color: rgb(63, 60, 60);
  font-size: bold;
}
</style>
