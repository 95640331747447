<template>
  <div class="mt-1 mx-3">
    <various-accounting></various-accounting>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import VariousAccounting from "@/components/accounting/various-accounting/index.vue";

export default {
  props: {},
  components: {
    VariousAccounting,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  created() {
    this.notifyQueue({
      text: "Contabilità",
      path: "",
      level: 1,
    });
    this.notifyQueue({
      text: "Movimenti Vari",
      path: "module.CONT.MOVV",
      level: 2,
    });
  },
};
</script>
