<template>
  <b-card header="true" header-tag="header">
    <div
      slot="header"
      class="d-flex justify-content-between align-items-center"
    >
      <p class="mb-0 d-inline-block align-middle">
        <b>{{ header }}</b>
      </p>
    </div>
    <div v-for="(entry_detail, index) in data.entry_details" :key="index">
      <b-row v-if="entry_detail.gross !== 0">
        <div class="col-md-4 border-bottom" v-if="data.entry_details.length">
          {{ entry_detail.treasury.title }}
        </div>
        <div class="col-md-8 border-bottom" v-if="data.entry_details.length">
          {{ toLocaleCurrency(entry_detail.gross) }}
        </div>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { toLocaleCurrency } from "@/utils/strings";

export default {
  data() {
    return {};
  },
  props: {
    header: String,
    repository: String,
    fields: {
      type: Array,
    },
    beForm: {
      type: Object,
    },
    data: {
      type: Object,
    },
    labels: {
      type: Object,
    },
  },
  methods: {
    toLocaleCurrency,
    getRegistry(registry) {
      return registry.status_registry.value === 0
        ? `${registry.attributables.NAME}-${registry.attributables.SURN}`
        : `${registry.attributables.CNAM}`;
    },
    getBroker(broker) {
      return `${broker.attributables.BUID}`;
    },
  },
  computed: {},
};
</script>
<style scoped>
.card {
  border: none;
}
.col-md-4.border-bottom {
  text-transform: uppercase;
  color: rgb(63, 60, 60);
  font-size: bold;
}
#informazioni {
  margin-right: 560px;
}
</style>
