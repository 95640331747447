<template>
  <div class="mt-1 mx-3">
    <various-accounting> </various-accounting>
  </div>
</template>

<script>
import VariousAccounting from "@/components/profile/various-accountings/create.vue";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    VariousAccounting,
  },
  created() {
    this.notifyQueue({
      text: "Crea Movimento Vario",
      path: "various_accounting.create",
      level: 3,
    });
  },
};
</script>
