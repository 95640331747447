<template>
  <b-card
    header="true"
    header-tag="header"
    v-if="data.registries.length > 0 || data.registry_id"
  >
    <div
      slot="header"
      class="d-flex justify-content-between align-items-center"
    >
      <p class="mb-0 d-inline-block align-middle">
        <b>{{ header }}</b>
      </p>
    </div>
    <b-row v-for="(field, index) in fields" :key="index">
      <div v-if="statusRegistryField(field)" class="col-md-4 border-bottom">
        {{ field.label }}
      </div>
      <div class="col-md-8 border-bottom" v-if="statusRegistryField(field)">
        {{ text(field.value) }}
      </div>
    </b-row>
  </b-card>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    header: String,
    repository: String,
    fields: {
      type: Array,
    },
    beForm: {
      type: Object,
    },
    data: {
      type: Object,
    },
    labels: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    statusRegistryField(field) {
      if (this.data.registries.length > 0) {
        return this.data.registries[0].status_registry.value ===
          field.status_registry || field.status_registry === undefined
          ? true
          : false;
      } else if (this.data.registry_id) {
        return this.data.registry.status_registry.value ===
          field.status_registry || field.status_registry === undefined
          ? true
          : false;
      }
    },
  },
  computed: {
    label() {
      return (label) => {
        return this.labels[this.repository][label];
      };
    },
    text() {
      return (value) => {
        if (this.data.registries) {
          return this.data.registries[0].attributables[value];
        } else if (this.data.registry_id) {
          return this.data.registry.attributables[value];
        }
      };
    },
    hasLabels() {
      return Object.keys(this.labels).length && this.labels[this.rep];
    },
  },
};
</script>
<style scoped>
.card {
  border: none;
}
.col-md-4.border-bottom {
  text-transform: uppercase;
  color: rgb(63, 60, 60);
  font-size: bold;
}
#informazioni {
  margin-right: 560px;
}
</style>
